import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Amplify, Auth } from 'aws-amplify';
import axios from "axios";

import Header from "./Header";
import Footer from "./Footer";
import URLTable from "./History/URLTable";

import ConfigAuth from "./Auth/Auth";

Amplify.configure({ Auth: ConfigAuth });
const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });
const org_name = window.location.host.split('.')[0];

const createdColumns = [
    { Header: "作成日時", accessor: "created_at" },
    { Header: "タイトル", accessor: "title" },
    { Header: "共有URL", accessor: "share_url" },
    { Header: "オリジナルURL", accessor: "origin_url" },
    { Header: "閲覧した人", accessor: "signature_texts" },
];

const History = (props) => {
    const [ pastURLObjects, setPastURLObjects] = useState([]);
    const [ columns, setColumns ] = useState(createdColumns);

    const getHisoricalURLs = (user_name) => {
        if (user_name !== undefined && org_name !== undefined) {
            API.post(`/history/created`, {
                'Content-Type': 'application/json',
                'org_name': org_name,
                'user_name': user_name,
            },)
                .then((res) => {
                    setPastURLObjects(res.data.contents);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const checkAuth = async (jwtToken) => {
        await API.post('/api/check_auth', { 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`,
        }).then((res) => {
                localStorage.setItem('auth_flag', res.data.result);
            }).catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        document.title = `履歴 | ${org_name} | Oudan Sharing`;
        setColumns(createdColumns);
        Auth.currentAuthenticatedUser()
            .then(async (res) => {
                if (localStorage.getItem('auth_flag') !== 'false') {
                    let jwtToken = res['signInUserSession']['idToken']['jwtToken']
                    await checkAuth(jwtToken);
                    await getHisoricalURLs(res.username);
                } else {
                    await getHisoricalURLs(res.username);
                }
            })
            .catch(() => {
                props.history.push("/login");
            });
    }, [props]);

    return (
        <div className="main">
            <Header />
            <div className="contents">
                <div className="url-table-title">
                    <h2>これまでに共有した情報</h2>
                </div>
                <URLTable
                    columns={columns}
                    URLObjects={pastURLObjects}
                />
            </div>
            <Footer />
        </div>
    )

}

export default withRouter(History);