import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import Home from "./components/Home";
import History from "./components/History";
import Profile from "./components/Profile";
import Login from "./components/Login";
import SharedURL from "./components/SharedURL";
import NotFound from "./components/NotFound";
import { AuthProvider } from "./components/Auth/AuthContext";

import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fab, fas, far);

function App() {
  return (
    <AuthProvider>
      <CookiesProvider>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/" component={Home} />
            <Route exact path="/history" component={History} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/shared_url/:id" component={SharedURL} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </CookiesProvider>
    </AuthProvider>
  );
}

export default App;