import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Amplify, Auth } from 'aws-amplify';
import axios from "axios";

import Header from "./Header";
import Footer from "./Footer";
import UserCard from "./Profile/UserCard";

import ConfigAuth from "./Auth/Auth";

Amplify.configure({ Auth: ConfigAuth });
const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });
const org_name = window.location.host.split('.')[0];

const Profile = (props) => {
    const [ user, setUser ] = useState(null);

    const checkAuth = async (jwtToken) => {
        await API.post('/api/check_auth', { 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`,
        }).then((res) => {
                localStorage.setItem('auth_flag', res.data.result)
            }).catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        document.title = `プロフィール | ${org_name} | Oudan Sharing`;
        Auth.currentAuthenticatedUser()
            .then((res) => {
                setUser({
                    username: res.username,
                    email: res.attributes.email,
                });
                if (localStorage.getItem('auth_flag') !== 'false') {
                    checkAuth(res['signInUserSession']['idToken']['jwtToken']);
                }
            })
            .catch(() => {
                props.history.push("/login");
            });
    }, [props]);

    return (
        <div className="main">
            <Header />
            <div className="contents">
                <UserCard 
                    history={props.history}
                    user={user}
                />
            </div>
            <Footer />
        </div>
    )

}

export default withRouter(Profile);