import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Amplify, Auth } from 'aws-amplify';
import axios from "axios";

import Header from "./Header";
import Footer from "./Footer";
import InputURL from "./Home/InputURL";
import ShareURL from "./Home/ShareURL";

import ConfigAuth from "./Auth/Auth";

Amplify.configure({ Auth: ConfigAuth });
const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });
const org_name = window.location.host.split('.')[0];

const Home = (props) => {
    const [ shareURLCardState, setShareURLCardState] = useState(false);
    const [ shareURL, setShareURL] = useState(null);
    const [ shareURLTitle, setShareURLTitle] = useState(null);
    const [ createdURL, setCreatedURL] = useState();
    const [ user, setUser ] = useState(null);

    const checkAuth = async (jwtToken) => {
        await API.post('/api/check_auth', { 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`,
        }).then((res) => {
                localStorage.setItem('auth_flag', res.data.result);
            }).catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        document.title = `ホーム | ${org_name} | Oudan Sharing`;
        Auth.currentAuthenticatedUser()
            .then((res) => {
                setUser({
                    username: res.username,
                    email: res.attributes.email,
                });
                if (localStorage.getItem('auth_flag') !== 'false') {
                    checkAuth(res['signInUserSession']['idToken']['jwtToken']);
                }
            })
            .catch(() => {
                props.history.push("/login");
            });
    }, [props]);

    const hundleCreateURL = async (url, title) => {
        await API.post('/share_url/create', {
            'Content-Type': 'application/json',
            'org_name': org_name,
            'user_name': user.username,
            'origin_url': url,
            'title': title,
        }).then((res) => {
            let created_url_obj = res.data.contents;
            setCreatedURL(created_url_obj['share_url']);
            setShareURLCardState(true);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleClickCreateURL = (e) => {
        e.preventDefault();
        if (shareURL !== null && shareURLTitle !== null) {
            hundleCreateURL(shareURL, shareURLTitle);
        }
    };

    return (
        <div className="main">
            <Header />
            <div className="contents">
                <InputURL
                    handleClickCreateURL={handleClickCreateURL}
                    shareURL={shareURL}
                    setShareURL={setShareURL}
                    shareURLTitle={shareURLTitle}
                    setShareURLTitle={setShareURLTitle}
                />
                {shareURLCardState ?
                    <ShareURL
                        createdURL={createdURL}
                    />
                : null}
            </div>
            <Footer />
        </div>
    );
};

export default withRouter(Home);