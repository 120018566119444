import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { Amplify, Auth } from 'aws-amplify';
import ConfigAuth from "../Auth/Auth";
import { AuthContext } from "../Auth/AuthContext";

Amplify.configure({ Auth: ConfigAuth });
const org_name = window.location.host.split('.')[0];

const InputURL = (props) => {
    const {
        signin,
        warningMessage,
        setWarningMessage,
    } = useContext(AuthContext);
    const pathname = window.location.pathname;

    const [ submited, setSubmited ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ username, setUsername ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ newPassword, setNewPassword ] = useState("");
    const [ isFirstSignIn, setIsFirstSignIn ] = useState(false);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(() => {
                if (pathname !== "/") {
                    props.history.push("/");
                }
            })
            .catch(() => {});
    }, [props, pathname]);

    const hundleOnChangeUsername = (value) => {
        setUsername(value);
        if (isFirstSignIn) {
            if (password !== "" && newPassword !== "") {
                setSubmited(true);
            }
        } else {
            if (password !== "") {
                setSubmited(true);
            }
        }
    };

    const hundleOnChangePassword = (value) => {
        setPassword(value);
        if (isFirstSignIn) {
            if (username !== "" && newPassword !== "") {
                setSubmited(true);
            }
        } else {
            if (username !== "") {
                setSubmited(true);
            }
        }
    };

    const hundleOnChangeNewPassword = (value) => {
        setNewPassword(value);
        if (username !== "" && password !== "") {
            setSubmited(true);
        }
        setSubmited(true);
    };

    const hundleOnClickSignIn = (e) => {
            e.preventDefault();
            setWarningMessage("");
            setSubmited(false);
            signin(props.history, org_name, username, password, newPassword, isFirstSignIn);
    }

    const renderButton = () => {
        let displayText = "ログイン";
        if (isFirstSignIn === true) {
            displayText = "新規ログイン";
        };
        // Before clicking the login button: If not entered
        if (submited === false && loading === false) {
            return <button className="button disabled-button" type="submit" disabled>{displayText}</button>
        }
        // Before clicking the login button: If entered
        else if (submited === true && loading === false) {
            return <button className="button" type="submit"
                    onClick={(e) => {hundleOnClickSignIn(e)}}>{displayText}</button>
        } 
        // After clicking the login button
        else if (submited === true && loading === true) {
            return <button className="button disabled-button" type="submit" disabled>ログイン中</button>
        }
    };

    const hundleSignInState = () => {
        if (isFirstSignIn === false) {
            setIsFirstSignIn(true);
            setSubmited(false);
            setLoading(false);
            setWarningMessage("");
        } else {
            setIsFirstSignIn(false);
            setSubmited(false);
            setLoading(false);
            setWarningMessage("");
        }
        
    };

    const renderSignInState = () => {
        if (isFirstSignIn === false) {
            return <p>まだ仮登録の方はこちら</p>
        } else {
            return <p>登録済みの方はこちら</p>
        }
    };

    const renderInputNewPassword = () => {
        if (isFirstSignIn === true) {
            return (
                <input className="textarea" type="password" required
                    placeholder="新規パスワードを入力"
                    onChange={(e)=>{
                        hundleOnChangeNewPassword(e.target.value);
                    }} />
            )
        }
    };

    const renderPasswordRequirements = () => {
        if (isFirstSignIn === true) {
            return(
                <div className="password-requirements">
                    <p>
                        新規パスワード要件：<br />
                        ・最小 8 文字<br />
                        ・少なくとも 1 つの数字を含む<br />
                        ・少なくとも 1 つの特殊文字(記号)を含む<br />
                        ・少なくとも 1 つの小文字を含む<br />
                        ・少なくとも 1 つの大文字を含む<br />
                    </p>
                </div>
            )
        }
    };

    return (
        <div>
            <h2>ログイン</h2>
            <form className="login-input-card">
                <div className="signin-type-seletct" onClick={(_)=>hundleSignInState()}>
                    {renderSignInState()}
                </div>
                <input className="textarea" type="username" placeholder="ユーザー名を入力" required
                    onChange={(e)=>{
                        hundleOnChangeUsername(e.target.value);
                    }} />
                <input className="textarea" type="password" required
                    placeholder={isFirstSignIn ? "仮パスワードを入力" : "パスワードを入力"}
                    onChange={(e)=>{
                        hundleOnChangePassword(e.target.value);
                    }} />
                {renderInputNewPassword()}
                {renderPasswordRequirements()}
                <div className="warning-message">
                    <p>{warningMessage}</p>
                </div>
                {renderButton()}
            </form>
        </div>
    )

}

export default withRouter(InputURL);