import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InputURL = (props) => {
    const handleClickCreateURL = props.handleClickCreateURL;
    const shareURL = props.shareURL;
    const setShareURL = props.setShareURL;
    const shareURLTitle = props.shareURLTitle;
    const setShareURLTitle = props.setShareURLTitle;
    const [ validURL, setValidURL ] = useState(false);
    const [ isSubmit, setIsSubmit ] = useState(false);

    const hundleSubmit = (e) => {
        e.preventDefault();
        setIsSubmit(true);
        handleClickCreateURL(e);
    };

    const renderButton = () => {
        if (validURL && (shareURL !== null && shareURLTitle !== null)) {
            if (isSubmit) {
                return <button className="button disabled-button" type="submit" disabled>URL を作成</button>;
            } else {
                return <button className="button" type="submit" onClick={(e) => {hundleSubmit(e)}}>URL を作成</button>;
            }
        } else {
            return <button className="button disabled-button" type="submit" disabled>URL を作成</button>;
        }
    };

    const renderWaringMessage = () => {
        if (!validURL && (shareURL !== null && shareURLTitle !== null)) {
            return (
                <div className="url-valid-popup">
                    <FontAwesomeIcon icon="fas fa-times-circle" />
                    <p className="url-valid-text">入力された URL は形式が正しくありません！</p>
                </div>
            )
        }
    };

    return (
        <div>
            <h2>共有したい URL を入力してください</h2>
            <form className="url-input-card">
                <input className="textarea" type="url" placeholder="https://example.io/123456" required
                    pattern="https?://[\w!\?/\+\-_~=;\.,\*&@#\$%\(\)'\[\]]+"
                    onChange={(e)=>{
                        setShareURL(e.target.value)
                        setValidURL(e.target.validity.valid)
                    }} />
                {renderWaringMessage()}
                <input className="textarea" type="textarea" placeholder="URL のタイトルを入力" required
                    onChange={(e)=>{setShareURLTitle(e.target.value)}} />
                {renderButton()}
            </form>
        </div>
    );
};

export default InputURL;