import React, { useState } from "react";
import { useTable, useSortBy } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const URLTable = (props) => {
    const [ copied, setCopied ] = useState(false);
    const URLObjects = props.URLObjects;
    const columns = props.columns;
    const style = { color: "#ffffff" };
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        { columns: columns, data: URLObjects },
        useSortBy
    );

    const handleClickCopy = (i,j) => {
        navigator.clipboard.writeText(URLObjects[i][columns[j].accessor]);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    const renderSortIcon = (column) => {
        if (column.isSorted) {
            if (column.isSortedDesc) {
                return <FontAwesomeIcon  className="sort-icon" icon="fas fa-caret-down" size="lg" style={style} />;
            } else {
                return <FontAwesomeIcon className="sort-icon" icon="fas fa-caret-up" size="lg" style={style} />;
            }
        }
    }

    const renderPastURLTableUsingReactTable = () => {
        return (
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>
                                        {renderSortIcon(column)}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={i}>
                                {row.cells.map((cell, j) => {
                                    // #TODO: ユーザ名の改行を行う
                                    // console.log(cell.column.id === 'signature_texts');
                                    return (
                                        <td {...cell.getCellProps()} className="table-cell" onClick={()=>{handleClickCopy(i,j)}}>
                                            {cell.render("Cell")}
                                        </td>
                                    )
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        )
    }

    const renderCopiedMessage = () => {
        if (copied) {
            return(
                <div className="copied-popup" >
                    <FontAwesomeIcon icon="fas fa-check-circle" />
                    <p className="copied-text">コピーしました！</p>
                </div>
            )
    }}

    return (
        <div>
            {renderCopiedMessage()}
            <div className="table-card">
                {renderPastURLTableUsingReactTable()}
            </div>
        </div>
    )

}

export default URLTable;