import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import Header from "./Header";
import Footer from "./Footer";

const org_name = window.location.host.split('.')[0];

const NotFound = (props) => {
    useEffect(() => {
        document.title = `NotFound | ${org_name} | Oudan Sharing`;
    }, []);

    return (
        <div className="main">
            <Header />
            <div className="contents">
                <p>申し訳ありませんがこちらのページは存在しません。</p>
                <Link to="/">トップページへ</Link>
            </div>
            <Footer />
        </div>
    )

}

export default withRouter(NotFound);