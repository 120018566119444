import React, { useEffect, useState } from "react";
import { Amplify, Auth } from 'aws-amplify';
import {
    CognitoUser,
    AuthenticationDetails,
    CognitoUserPool,
} from "amazon-cognito-identity-js";
import ConfigAuth from "./Auth";
import Vocabularies from "../AwsErrorTrans/Trans";
import axios from "axios";

Amplify.configure({ Auth: ConfigAuth });
const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [ warningMessage, setWarningMessage ] = useState("");

    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
        ClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    });

    const hundleCreateNewUserNode = async (history, org_name, username) => {

        await API.post('/user/create', { 
            'Content-Type': 'application/json',
            'org_name': org_name,
            'user_name': username,
        }).then(() => {
                localStorage.setItem('auth_flag', true);
                history.push('/');
            }).catch((err) => {
                console.log(err);
            });
    }

    const signin = (history, org_name, username, password, newPassword, isFirstSignIn) => {
        const authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        });

        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        });

        try {
            if (isFirstSignIn) {
                cognitoUser.authenticateUser(authenticationDetails, {
                    onSuccess: () => {
                        localStorage.setItem('auth_flag', true);
                        history.push('/');
                    },
                    onFailure: (err) => {
                        setWarningMessage(Vocabularies["ja"][err.message]);
                    },
                    newPasswordRequired: (userAttributes) => {
                        cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
                            onSuccess: () => {
                                hundleCreateNewUserNode(history, org_name, username);
                            },
                            onFailure: (err) => {
                                setWarningMessage(Vocabularies["ja"][err.message]);
                            }
                        });
                    }
                });
            } else {
                cognitoUser.authenticateUser(authenticationDetails, {
                    onSuccess: () => {
                        localStorage.setItem('auth_flag', true);
                        history.push('/');
                    },
                    onFailure: (err) => {
                        setWarningMessage(Vocabularies["ja"][err.message]);
                        if (err.message === "callback.newPasswordRequired is not a function") {
                            setWarningMessage("まだ仮登録状態です。新規ログインを行なってください。");
                        }
                    }
                });
            }
        } catch (err) {
            setWarningMessage(Vocabularies["ja"][err.message]);
        }
    };

    const signout = async () => {
        try {
            await Auth.signOut();
        } catch (err) {
            alert("サインアウトに失敗しました。");
        }
    };

    useEffect(() => {
    }, []);

    return (
        <AuthContext.Provider 
            value={{
                signin: signin,
                signout: signout,
                warningMessage: warningMessage,
                setWarningMessage: setWarningMessage,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};