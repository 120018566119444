import React, { useState, useContext } from "react";
import { AuthContext } from "../Auth/AuthContext";

const contentsAttributeNames = {
    username: "ユーザー名",
    email: "E-mail",
}
const UserCard = (props) => {
    const {
        signout,
    } = useContext(AuthContext);
    const user = props.user;
    const [ submited, setSubmited ] = useState(false);

    const renderProfileContens = () => {
        if (user !== null) {
            return (
                Object.keys(user).map((key, index) => {
                    return (
                        <div className="table-row-profile" key={index}>
                            <div className="table-row-profile-content">
                                <p className="table-key">{contentsAttributeNames[key]}</p>
                                <p className="table-value-user-card">{user[key]}</p>
                            </div>
                        </div>
                    )
                })
            )
        }
    }

    const hundleOnClickSignout = async (e) => {
        e.preventDefault();
        await signout();
        await setSubmited(true);
        await localStorage.removeItem('auth_flag')
        await props.history.push("/login");
    }

    const handleButton = () => {
        let displayText = "サインアウト";
        if (!submited) {
            return <button className="button" type="submit"
                        onClick={(e) => {hundleOnClickSignout(e)}}>{displayText}</button>
        } 
        else {
            return <button className="button disabled-button" type="submit" disabled>サインアウト中</button>
        }
    };

    return (
        <div>
            <h2>ユーザー情報</h2>
            <form className="past-url-card-table">
                {renderProfileContens()}
                {handleButton()}
            </form>
        </div>
    )

}

export default UserCard;