import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Amplify, Auth } from 'aws-amplify';
import axios from "axios";

import Header from "./Header";
import Footer from "./Footer";
import URLCard from "./SharedURL/URLCard";
import Signature from "./SharedURL/Signature";

import ConfigAuth from "./Auth/Auth";

Amplify.configure({ Auth: ConfigAuth });
const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });
const org_name = window.location.host.split('.')[0];
const shared_id = window.location.pathname.split('/')[2];

const SharedURL = (props) => {
    const [ sharedURL, setSharedURL ] = useState(null);
    const [ user, setUser ] = useState(null);

    const getSharedURL = async () => {
        await API.get(`/shared_url/${shared_id}`)
            .then((res) => {
                setSharedURL(res.data.contents);
                let title = res.data.contents.title
                let created_user = res.data.contents.created_user
                document.title = `${title} - ${created_user} | ${org_name} | Oudan Sharing`;
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const checkAuth = async (jwtToken) => {
        await API.post('/api/check_auth', { 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`,
        }).then((res) => {
                localStorage.setItem('auth_flag', res.data.result);
            }).catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(async (res) => {
                setUser({
                    username: res.username,
                    email: res.attributes.email,
                });
                if (localStorage.getItem('auth_flag') !== 'false') {
                    await checkAuth(res['signInUserSession']['idToken']['jwtToken']);
                }
            })
            .catch(() => {
                localStorage.setItem('auth_flag', false);
            });
        getSharedURL();
    }, [props]);

    const renderSignatureCard = () => {
        const user_name = user ? user.username : null;
        const created_user = sharedURL ? sharedURL.created_user : null;
        if (user_name !== created_user) {
            return (
                <Signature
                    org_name={org_name}
                    user_name={user_name}
                    shared_id={shared_id}
                />
            )
        }
    }

    return (
        <div className="main">
            <Header />
            <div className="contents">
                <URLCard
                    sharedURL={sharedURL}
                />
            </div>
            {renderSignatureCard()}
            <Footer />
        </div>
    )

}

export default withRouter(SharedURL);