import React, { useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ShareURL = (props) => {
    const createdURL = props.createdURL;
    const [ copied, setCopied] = useState(false);

    const handleClickCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    const renderCopiedMessage = () => {
        if (copied) {
            return (
                <div className="copied-popup">
                    <FontAwesomeIcon icon="fas fa-check-circle" />
                    <p className="copied-text">コピーしました！</p>
                </div>
            )
        }
    };

    return (
        <div>
            <h2>この URL で共有しましょう</h2>
            <div className="url-input-card">
                <p className="created-url">{createdURL}</p>
                <CopyToClipboard text={createdURL} onCopy={() => {handleClickCopy()}}>
                    <input className="button" type="submit" value="コピーする"></input>
                </CopyToClipboard>
                {renderCopiedMessage()}
            </div>
        </div>
    )

}

export default ShareURL;